import axios from "axios"

const BASE = 'https://hook.us1.make.com/v4mv7t45ewxge5w47ti9dbeqcqb3vubi'

export const MAKE = {
    webhook: async (atendente,feedback, nota, data, hora, id, texto ) => {
        const response = await axios.post(`${BASE}`, {
            atendente,feedback, nota, data, hora, id, texto
        })
        return response.data
    }
}