import { Layout } from "./components/Layout"

import { useState, useEffect } from 'react';
import { PageLoader } from './components/PageLoader';

export default function App() {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000);
    }, []);

    return (
        <>
            {loading && <PageLoader />}
            {!loading && <Layout />}
        </>
    )
} 