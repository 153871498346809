import React from "react";
import { Route, Routes, Navigate } from 'react-router-dom'
import { Layout } from "../components/Layout"

export const Router = () => {
    return (
        <Routes>
            <Route path="atendente/:gen?/:name?/:id" element={<Layout />} />

            <Route path="/*" element={<Navigate to="/atendente" />} end />
        </Routes>
    )
}
