import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { MAKE } from "../../services/make";

import "./App.css";

import { TextWriter } from "../Text"
import { Menu } from "../Menu";
import { Button } from "../Button"
import { Footer } from "../Footer";
import "../Menu/Menu.css";

export const Layout = () => {
  const [atendente, setAtendente] = useState('');
  const [botao, setBotao] = useState()
  const { name } = useParams()
  const { gen } = useParams()
  const { id } = useParams()

  const [genHM, setGenHM] = useState()

  useEffect(() => {
    if (gen === "h") {
      setGenHM(true)

      return
    }
    if (gen === "m") {
      setGenHM(false)
    }

  }, [gen, genHM])

  const [nota, setNota] = useState();
  const [feedback, setFeedback] = useState();
  const [texto, setTexto] = useState();

  function handleAtendente() {
    setAtendente(name ?? 'SeuBoné')
  }

  const notify = (feedback) => toast(`O atendente ${atendente} recebeu o feedback: ${feedback}`);

  useEffect(() => {
    handleAtendente()
  })

  const formatName = (name) => {
    return `${name[0].toUpperCase()}${name.substring(1)}`
}

  const handleFeedback = async (feedback, nota, texto) => {

    let d = new Date()
    let hora = `${d.getHours()}:${d.getMinutes()}`

    let data = d.toLocaleDateString()

    notify(feedback)

    await MAKE.webhook(formatName(atendente), feedback, nota, data, hora, id, texto)

    setTimeout(() => {
      window.location.href = 'https://seubone.com.br';
    }, 6000)
  };

  const handleCallback = (feedback, nota, texto) => {
    setBotao(nota)
    setFeedback(feedback)
    setNota(nota)
    setTexto(texto)

  }
  // console.log(feedback, nota, texto)

  const sendSheet = () => {
    if (typeof atendente === "undefined") {
      alert("Sem nome de atendente")
    } else if (typeof nota === "undefined") {
      alert("Satisfação não marcada!")
    } else {
      handleFeedback(feedback, nota, texto)
    }
  }

  return (
    <div className="App">
      <Menu />
      <ToastContainer />
      <div className="centerContent">
        <div className="contentTitle">
          <TextWriter
            text1="Pesquisa de Satisfação"
            text2="Seu feedback nos ajuda a melhorar nosso atendimento"
          />
        </div>

        {/* <div class="divisor">
        </div> */}


        <div className="container">
          <label className="container-label">
            <span className="subtextx">
              {genHM ? "O quão satisfeito você está com atendimento do"
                :
                "O quão satisfeito você está com atendimento da"
              }
            </span>

            <span>{atendente + '?'}</span>

          </label>

          <div className="feedback-buttons">
            <Button emoji="😒" ativo={botao === 1} label="Insatisfeito" onClick={() => handleCallback("Insatisfeito", 1, feedback)} />
            <Button emoji="😊" ativo={botao === 5} label="Satisfeito" onClick={() => handleCallback("Satisfeito", 5, feedback)} />
            
          </div>
          <div>
            <textarea className="feedback-text"  placeholder="Deixe seu feedback" onChange={(e) => setTexto(e.target.value)} />
          </div>

          <button onClick={() => sendSheet()} className="btnEnviar" type="button" >Enviar feedback</button>
          
        </div>

      </div>
      <Footer />
    </div>
  )
}