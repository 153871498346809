import "./Button.css";

export const Button = ({onClick, ativo, emoji, label}) => {

    return (
        <button
            className={`${ativo ? 'ativo' : ''} feedback-buttons-icons`}
            onClick={onClick}
        >
            <span className="emojix">{emoji}</span>
            <span className="textx">{label}</span></button>
    )
};