import "./Menu.css";

export const Menu = () => {
    return (
        <nav className="nav">
            <div>
                <img src="/image/index.svg" alt="logo" />
            </div>
            
            <div>
                <a href="https://seubone.com.br">Volta para Home</a>
            </div>
        </nav>
    );
};