import React from 'react';
import './PageLoader.css';

export const PageLoader = () => {
  return (
    <div className="page-loader">
      <div className="loader"></div>
      <div className="loader-text">Seu Boné...</div>
    </div>
  );
};
